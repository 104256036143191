import ReactMapGL from 'react-map-gl'
import { useElementSize } from '@kaliber/use-element-size'

import { useConfig } from '/machinery/Config'

import 'mapbox-gl/dist/mapbox-gl.css'
import styles from './Map.css'

export function Map({ viewport, onViewportChange, children }) {
  const { mapbox } = useConfig()
  const { size: { width = 0, height = 0 }, ref } = useElementSize()
  const [ready, setReady] = React.useState(false)

  const { transitionDuration, ...staticViewport } = viewport

  return (
    <div className={styles.component} {...{ ref }} style={{ opacity: ready ? 1 : 0 }}>
      <ReactMapGL
        mapStyle='mapbox://styles/mapbox/outdoors-v10?optimize=true'
        attributionControl={false}
        mapboxAccessToken={mapbox.token}
        onLoad={() => { setReady(true) }}
        transitionDuration={ready && transitionDuration || 1000}
        transitionEasing={ready && easeInOutCubic}
        {...staticViewport}
        {...{
          width,
          height,
          onViewportChange,
          children
        }}
      />
    </div>
  )
}

function easeInOutCubic(x) {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
