import { I18nProvider, useI18n, useI18nLanguage } from '@kaliber/i18n'
import { LocationProvider, useRoutes, useRouting, useLocationMatch, usePick } from '@kaliber/routing'
import { QueryClientProvider } from 'react-query'

import { ConfigProvider } from '/machinery/Config'
import { SanityProvider } from '/machinery/Sanity'
import { MapPositionContextProvider } from '/machinery/MapPosition'
import { ThemeProvider, useTheme } from '/machinery/Theme'
import { usePageRouteData, PageRouteDataContextProvider } from '/machinery/PageRouteData'

import { themes } from '/themes'
import { i18n } from '/i18n'
import { routeMap } from './routeMap'
import { INTERNAL_SERVER_ERROR, NOT_FOUND } from './machinery/statusCodes'
import { createQueryClient } from './machinery/queryClient'
import { SettingsProvider } from './machinery/Settings'
import { Navigation } from '/sub/navigation/Navigation'
import { StorylineList } from '/sub/storyline/StorylineList'
import { StoryList } from '/sub/story/StoryList'
import { ButtonBack } from '/sub/button/Button'

import styles from './App.css'
import theme from '/themes/blue-dark.css'

// Pages
import { Home } from '/pages/Home'
import { Page } from '/pages/Page'
import { Storyline } from '/pages/Storyline'
import { NotFound } from '/pages/NotFound'
import { ServerError } from '/pages/ServerError'

export default function App({ settings, config, dehydratedState, location }) {
  /*
    We don't want the queries to be cached on the server, so we don't put them at the root of the
    file. This component is only rendered once, so the effect in the client is the same.
    This has the added benefit that hot reloading will clear the cache (are you sure?).
  */
  const queryClient = createQueryClient()

  return (
    <AppContextProvider {...{ config, settings, location, dehydratedState, queryClient }}>
      <AppImpl />
    </AppContextProvider>
  )
}

function AppContextProvider({ config, settings, location, dehydratedState, queryClient, children }) {
  return (
    <ConfigProvider value={config}>
      <SettingsProvider value={settings}>
        <QueryClientProvider client={queryClient}>
          <LocationProvider initialLocation={location} {...{ routeMap }}>
            <SanityProvider config={config.sanity}>
              <PageRouteDataContextProvider {...{ dehydratedState }}>
                <MapPositionContextProvider>
                  <ThemeProvider {...{ theme }}>
                    {children}
                  </ThemeProvider>
                </MapPositionContextProvider>
              </PageRouteDataContextProvider>
            </SanityProvider>
          </LocationProvider>
        </QueryClientProvider>
      </SettingsProvider>
    </ConfigProvider>
  )
}

function AppImpl() {
  /** @type {import('/routeMap').routeMap} */
  const routes = useRoutes()
  const { matchRoutes } = useRouting()
  const { params } = useLocationMatch()
  const { data } = usePageRouteData()
  const fallbackTheme = useTheme()

  const { storyline } = data ?? {}
  const givenLanguage = params.language ?? storyline?.language
  const language = ['nl', 'en'].includes(givenLanguage) ? givenLanguage : 'nl'
  const isErrorStatus = [INTERNAL_SERVER_ERROR, NOT_FOUND].includes(data.status)

  const navigationEntries = !isErrorStatus && matchRoutes(
    [routes.home, <StorylineList />],
    [routes.storyline, <StoryList />]
  )

  const theme = themes[storyline?.theme] ?? fallbackTheme

  return (
    <I18nProvider value={i18n} {...{ language }}>
      <ThemeProvider {...{ theme }}>
        <div className={styles.componentImpl}>
          {navigationEntries && (
            <nav className={styles.navigation}>
              <Navigation>{navigationEntries}</Navigation>
            </nav>
          )}

          <main className={styles.main}>
            {(
              data.status === INTERNAL_SERVER_ERROR ? <ServerError /> :
              data.status === NOT_FOUND ? <NotFound /> :
              matchRoutes(
                [routes.home, ({ language }) => <Home {...{ language }} />],
                [routes.page, <Page />],
                [routes.storyline, <Storyline />],
                [routes.notFound, <NotFound />],
              )
            )}
          </main>

          <BackButton layoutClassName={styles.backButtonLayout} />
        </div>
      </ThemeProvider>
    </I18nProvider>
  )
}

function BackButton({ layoutClassName = undefined }) {
  const i18n = useI18n()
  const pick = usePick()
  const language = useI18nLanguage()
  const backLink = useBackLink()
  const exitLink = language === 'en' ? 'https://spoorwegmuseum.nl/en/' : 'https://spoorwegmuseum.nl'

  const route = pick(routeMap.home)

  return route && ['nl', 'en'].includes(route.params.language)
    ? <ButtonBack to={exitLink} {...{ layoutClassName }} dataX='link-to-spoorwegmuseum'>{i18n('back-to-spoorwegmuseum')}</ButtonBack>
    : <ButtonBack to={backLink} {...{ layoutClassName }} dataX='link-previous-page'>{i18n('back')}</ButtonBack>
}

function useBackLink() {
  const language = useI18nLanguage()
  const pick = usePick()
  const { params } = useLocationMatch()
  const { data } = usePageRouteData()
  const { floors = [] } = data || {}

  if (pick(routeMap.storyline.floorPlan.floor.story) && data.floor?.showTeaser) return routeMap.storyline.floorPlan.floor(params)
  if (pick(routeMap.storyline.floorPlan.floor) && floors.length > 1) return routeMap.storyline.floorPlan(params)
  if (pick(routeMap.storyline.geo.story)) return routeMap.storyline(params)
  if (pick(routeMap.storyline.floorPlan.floor) && floors.length !== 1) return routeMap.storyline(params)

  return routeMap.home({ language })
}

